import {
  Checkbox,
  PaymentMethodEnum,
  getPaymentMethodIcon,
  getPaymentMethodTitle,
} from '@systemeio/ui-shared'
import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel } from 'shared/components/form/field-label'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface PaymentMethodsProps extends FieldErrorAndDescriptionProps {
  value?: PaymentMethodEnum[]
  onChange: (value?: PaymentMethodEnum[]) => void
  data?: PaymentMethodEnum[]
  isPreFetching?: boolean
  required?: boolean
}

const PaymentMethods = ({
  value,
  onChange,
  data,
  isPreFetching,
  error,
  required,
}: PaymentMethodsProps) => {
  const { t } = useLocoTranslation()
  return (
    <div className={`text-sm font-medium flex-col gap-1`}>
      <div className={`flex-col gap-1 ${data && data.length === 0 ? 'hidden' : 'flex'}`}>
        <FieldLabel
          label={t('dashboard.payment_methods.allowed_payment_methods')}
          required={required}
        />
        <ul className={'flex flex-wrap flex-row gap-5'}>
          {isPreFetching || !data || !value ? (
            <PaymentMethodsSkeleton />
          ) : (
            data.map(el => {
              const isSelected = value.includes(el)
              return (
                <li key={el}>
                  <Checkbox
                    value={isSelected}
                    label={
                      <div
                        className={`flex gap-1.5 items-center [&>svg]:w-[20px] [&>svg]:h-[20px] ${
                          isSelected && '[&>svg]:fill-blue [&>svg]:main-transition-colors'
                        }`}
                      >
                        {getPaymentMethodIcon(el)}
                        {getPaymentMethodTitle(el)}
                      </div>
                    }
                    onChange={() =>
                      isSelected
                        ? onChange(value.filter(method => method !== el))
                        : onChange([...value, el])
                    }
                  />
                </li>
              )
            })
          )}
        </ul>
      </div>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

const PaymentMethodsSkeleton = () => (
  <div className={'flex gap-7 animate-pulse'}>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
    <div className={'bg-gray-600/40 px-7 h-[23px] rounded-lg text-transparent'}>.</div>
  </div>
)

export default PaymentMethods
