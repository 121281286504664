import { CurrencyEnum } from '@systemeio/ui-shared'
import { CurrencyNameEnum } from 'shared/enums/currency-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

export interface CurrencyDataInterface {
  caption: CurrencyNameEnum
  id: CurrencyEnum
}
export const useCurrencyData = (): CurrencyDataInterface[] => {
  const { t } = useLocoTranslation()
  return Object.values(CurrencyEnum).map(cur => ({
    id: cur,
    caption: t(CurrencyNameEnum[cur]),
  }))
}
