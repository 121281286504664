import {
  CurrencyEnum,
  FormInput,
  FormInputSearch,
  FormSelect,
  Hint,
  Popover,
  Tooltip,
} from '@systemeio/ui-shared'
import {
  FUNNELS_API,
  activateFunnel,
  createFunnel,
  removeFunnel,
  updateFunnel,
} from 'modules/funnels/api/funnels-api'
import FunnelDuplicateModal from 'modules/funnels/components/funnel-duplicate-modal'
import FunnelGoalSelector from 'modules/funnels/components/funnel-goal-selector'
import { FunnelsLoco } from 'modules/funnels/constants/funnels-loco'
import { useFunnel } from 'modules/funnels/funnel/hooks/use-funnel'
import { useFunnels } from 'modules/funnels/hooks/use-funnels'
import {
  FunnelFilterInterface,
  StatusDataInterface,
} from 'modules/funnels/types/funnel-filter-interface'
import {
  FunnelAdditionalEditableInterface,
  FunnelCreateInterface,
  FunnelEditableInterface,
  FunnelErrorsInterface,
  FunnelInterface,
} from 'modules/funnels/types/funnel-interface'
import { getFunnelUrl } from 'modules/funnels/utils/get-funnel-url'
import { isFunnelCreated } from 'modules/funnels/utils/is-funnel-created'
import PaymentMethods from 'modules/payment-methods/components/payment-methods'
import { useCurrency } from 'modules/payment-methods/hooks/use-currency'
import { usePaymentMethods } from 'modules/payment-methods/hooks/use-payment-methods'
import { usePaymentSettings } from 'modules/profile/payment/hooks/use-payment-settings'
import { ShareResourceEnum } from 'modules/share/enum/share-resource-enum'
import { getShareUrl } from 'modules/share/utils/get-share-url'
import { NextPage } from 'next'
import React, { useEffect, useState } from 'react'
import { CreateDataType } from 'shared/components/create-modal'
import LinkShareModal from 'shared/components/link-share-modal'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import ListingLayout from 'shared/components/listing-layout'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import TransLoco from 'shared/components/trans-loco'
import { BooleanStatusEnum } from 'shared/enums/status-enum'
import { useCurrencyData } from 'shared/hooks/use-currency-data'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import useUser from 'shared/hooks/use-user'
import useUserDomains from 'shared/hooks/use-user-domains'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import TimeCircleIcon from 'shared/icons/time-circle-icon'
import { useFilterPagination } from 'shared/route-query/hooks/use-filter-pagination'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { fetchWithErrors } from 'shared/utils/fetch-with-errors'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'
import { getDomainInfoLink } from 'shared/utils/get-domain-info-link'

const defaultErrors: FunnelErrorsInterface = {
  name: '',
  domain: '',
  currency: '',
  type: '',
  paymentMethods: '',
}

const defaultFilters: FunnelFilterInterface = { search: '', status: 'true' }

const Funnels: NextPage = () => {
  const { user, isWsAssistant } = useUser()
  const { t } = useLocoTranslation()

  const { userDomains, userDomainsSelect, userDefaultDomain } = useUserDomains()
  const { filter, setFilter, pagination, setPagination, limit, setLimit } =
    useFilterPagination(defaultFilters)
  const { paymentSettingsData } = usePaymentSettings(!isWsAssistant)

  const [errors, setErrors] = useState<FunnelErrorsInterface>(defaultErrors)
  const [defaultValues, setDefaultValues] = useState<FunnelCreateInterface>({
    name: '',
    currency: undefined,
    domain: undefined,
    type: undefined,
  })
  const [additionalData, setAdditionalData] = useState<FunnelAdditionalEditableInterface>({
    paymentMethods: undefined,
    domain: undefined,
    currency: undefined,
  })

  const { currentCurrency, setCurrentCurrency, showToast } = useCurrency({
    currencyChangeMessage: t('dashboard.funnel.currency_change_info'),
  })
  const { data: paymentMethodsData } = usePaymentMethods(currentCurrency)

  const { funnels, mutate, counter } = useFunnels({ limit, pagination, filter })
  const [funnelId, setFunnelId] = useState<number>()
  const { funnel, mutateFunnel } = useFunnel(funnelId)

  const getStaticFunnelItems = () => {
    const array = funnels?.items.map(el => `${FUNNELS_API}/${el.id}`)
    return array || []
  }

  const updateData = useUpdateData({
    setPagination,
    api: FUNNELS_API,
    constantApi: [`${FUNNELS_API}/get-payment-methods`, ...getStaticFunnelItems()],
  })

  const onUpdateData = async () => {
    await updateData(false, funnels)
  }

  const currencyData = useCurrencyData()

  useEffect(() => {
    if (userDefaultDomain) {
      setDefaultValues(prev => ({
        ...prev,
        domain: userDefaultDomain.id,
      }))
    }
    if (paymentSettingsData) {
      setDefaultValues(prev => ({
        ...prev,
        currency: paymentSettingsData.defaultCurrency,
      }))
    }
  }, [userDomains, paymentSettingsData, userDefaultDomain])

  useEffect(() => {
    if (funnel) {
      setAdditionalData({
        paymentMethods: funnel.paymentMethods,
        currency: funnel.currency,
        domain: funnel.domain.id,
      })
      setCurrentCurrency(funnel.currency)
    }
  }, [funnel, setCurrentCurrency])

  const getStatusData = (): StatusDataInterface[] =>
    Object.keys(BooleanStatusEnum).map(key => {
      const status = key as keyof typeof BooleanStatusEnum
      return { id: status, caption: t(BooleanStatusEnum[status]) }
    })

  const columns: ColumnDefinitionInterface<FunnelInterface>[] = [
    {
      key: 'name',
      header: t('global.title'),
      widthPercent: 73,
      onRender: funnel => (
        <div className="truncate">
          {isFunnelCreated(funnel) ? (
            <LinkWithoutPrefetch
              rel="noreferrer"
              className="primary-link w-full"
              href={getFunnelUrl(funnel.id)}
            >
              {funnel.name}
            </LinkWithoutPrefetch>
          ) : (
            <span className={'w-full'}>{funnel.name}</span>
          )}
        </div>
      ),
    },
    {
      key: 'status',
      header: t('global.status'),
      widthPercent: 10,
      onRender: funnel => {
        const StatusOfCreatedFunnel = (
          <Tooltip
            label={funnel.active ? t('global.active') : t('global.inactive')}
            mode="hover"
            key="status-tooltip"
          >
            <CheckCircleIcon
              className={`w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] ${
                funnel.active && 'fill-green'
              }`}
            />
          </Tooltip>
        )

        const StatusOfNotCreatedFunnel = (
          <Tooltip
            label={t('dashboard.funnel.creation_in_process')}
            mode="hover"
            key="status-tooltip"
          >
            <TimeCircleIcon className={`w-[16px] sm:w-[20px] h-[16px] sm:h-[20px] fill-yellow`} />
          </Tooltip>
        )
        return (
          <div className="lg:ml-4 flex items-center">
            {isFunnelCreated(funnel) ? StatusOfCreatedFunnel : StatusOfNotCreatedFunnel}
          </div>
        )
      },
    },
    {
      key: 'funnel.createdAt',
      header: t('global.created_on'),
      widthPercent: 17,
      onRender: funnel => (
        <span>
          {dateTimeFormat(funnel.createdAt, {
            timeFormat: user?.timeFormat,
            dateFormat: user?.dateFormat,
            timeZone: user?.timezone,
          })}
        </span>
      ),
    },
  ]

  const onCreateFunnel = async (data: CreateDataType<FunnelCreateInterface>) => {
    await fetchWithErrors(async () => {
      const newData: FunnelCreateInterface = {
        ...data,
        currency: data.currency || defaultValues.currency || CurrencyEnum.EURO,
      }
      await createFunnel(newData)
      await updateData(true, funnels)
    }, setErrors)
  }

  const onUpdateFunnel = async (funnel: FunnelInterface, data: FunnelEditableInterface) => {
    await fetchWithErrors(async () => {
      const newData: FunnelEditableInterface = {
        name: data.name,
        paymentMethods: data.paymentMethods,
        domain: data.domain,
        currency: data.currency,
      }
      const response = await updateFunnel(funnel.id, newData)
      await updateData(false, undefined, {
        mutationData: funnels,
        onMutate: async () => {
          await mutate(data => {
            if (data) {
              return {
                items: data.items.map(item => {
                  if (item.id === funnel.id) item = { ...item, name: response.data.name }
                  return item
                }),
                hasMore: data.hasMore,
              }
            }
          }, false)
        },
      })
      await mutateFunnel(data => data && { ...data, ...response.data }, false)
    }, setErrors)
  }

  const onActivateFunnel = async (funnel: FunnelInterface) => {
    await activateFunnel(funnel.id, funnel.active)
    await updateData(false, undefined, {
      mutationData: funnels,
      onMutate: async () => {
        await mutate(data => {
          if (data) {
            return {
              items: data.items.map(item => {
                if (item.id === funnel.id) item = { ...item, active: !funnel.active }
                return item
              }),
              hasMore: data.hasMore,
            }
          }
        }, false)
      },
    })
  }

  const onRemoveFunnel = async (funnel: FunnelInterface) => {
    await removeFunnel(funnel.id)
    await onUpdateData()
  }

  const onModalClose = () => setErrors(defaultErrors)

  return (
    <ListingLayout
      counter={counter}
      data={funnels}
      title={
        <h1 className={'flex gap-1.5 items-center'}>
          {t('dashboard.funnel.title_plural')}
          <Popover
            label={t('dashboard.funnel.title_tooltip')}
            popoverClassName={'w-[200px] sm:w-[350px]'}
          >
            {show => (
              <QuestionMarkIcon
                className={`${show ? 'fill-blue' : 'fill-gray-100'} group-focus-visible:fill-blue`}
              />
            )}
          </Popover>
        </h1>
      }
      type={'compact'}
      filterData={{
        filterer: { filter, setFilter, defaultFilters },
        paginator: { pagination, setPagination },
        limiter: { limit, setLimit },
        testAttributePostfix: 'filter-btn',
        filterable: [
          {
            field: 'search',
            onRender: (value, onChange) => (
              <FormInputSearch
                key={'funnel-search-filter'}
                onClear={() => onChange('')}
                className={'min-w-[150px]'}
                value={value}
                onChange={e => onChange(e.target.value)}
                testAttributePostfix={'search-input'}
              />
            ),
          },
          {
            field: 'status',
            onRender: (value, onChange) => (
              <FormSelect
                key={'funnel-status-filter'}
                data={getStatusData()}
                onChange={data => onChange(data)}
                value={value}
              />
            ),
          },
        ],
      }}
      createData={{
        onCreate: data => onCreateFunnel(data),
        defaultValues: defaultValues,
        caption: t('dashboard.funnel.title'),
        onAfterLeave: onModalClose,
        requiredFields: ['name', 'domain', 'type'],
        testAttributePostfix: 'create-btn',
        dataTestKey: 'funnel',
        creatable: [
          [
            {
              field: 'name',
              onRender: (value, onChange) => (
                <FormInput
                  key="funnel-name-create"
                  label={t('global.name')}
                  placeholder={t('global.name')}
                  required
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  maxLength={256}
                  error={errors.name}
                  data-test="funnel-name-field"
                />
              ),
            },
            {
              field: 'domain',
              onRender: (value, onChange) => (
                <FormSelect
                  key={'funnel-domain-create'}
                  label={
                    <div className={'flex gap-1 items-center'}>
                      {t('dashboard.funnel.funnel_domain')}
                      <Popover
                        label={
                          <TransLoco
                            t={t}
                            i18nKey={'dashboard.funnel.domain_tooltip'}
                            components={{
                              a: (
                                <a
                                  rel="noreferrer"
                                  target={'_blank'}
                                  className="primary-link w-full"
                                  href={getDomainInfoLink(user?.dashboardLocale)}
                                />
                              ),
                            }}
                          />
                        }
                        popoverClassName={'w-[200px] sm:w-[350px]'}
                      >
                        {show => (
                          <QuestionMarkIcon
                            className={`${
                              show ? 'fill-blue' : 'fill-gray-100'
                            } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                          />
                        )}
                      </Popover>
                    </div>
                  }
                  required
                  data={userDomainsSelect}
                  onChange={data => onChange(data)}
                  value={value}
                  error={errors.domain}
                />
              ),
            },
          ],
          {
            field: 'type',
            onRender: (value, onChange) => (
              <FunnelGoalSelector
                key={'funnel-type-create'}
                value={value}
                onChange={onChange}
                error={errors.type}
              />
            ),
          },
          {
            field: 'currency',
            onRender: (value, onChange) => (
              <FormSelect
                key={'funnel-currency-create'}
                label={t('global.currency')}
                data={currencyData}
                onChange={data => onChange(data)}
                value={value}
                error={errors.currency}
              />
            ),
          },
        ],
      }}
    >
      <div data-test="funnel-list">
        <Table
          data={funnels}
          columns={columns}
          caption={t('dashboard.funnel.title')}
          limiter={{ limit, setLimit }}
          paginator={{ pagination, setPagination }}
          testAttributePostfix="funnel"
          actionsOrder={['openLink', 'custom', 'activate', 'settings', 'remove']}
          actions={{
            openLink: [
              {
                shouldRender: funnel => isFunnelCreated(funnel),
                name: 'view-funnel',
                openInNew: true,
                setHref: funnel => funnel.viewLinkUrl,
              },
            ],
            custom: [
              {
                shouldRender: funnel => isFunnelCreated(funnel),
                name: 'duplicate',
                caption: t('dashboard.actions.title.duplicate'),
                onActionRender: (opened, onClose, funnel, key) => (
                  <FunnelDuplicateModal
                    key={key}
                    opened={opened}
                    onClose={() => {
                      onModalClose()
                      onClose()
                    }}
                    funnel={funnel}
                    onUpdateData={onUpdateData}
                  />
                ),
              },
              {
                shouldRender: funnel => isFunnelCreated(funnel),
                name: 'share',
                caption: t('dashboard.actions.title.share'),
                onActionRender: (opened, onClose, funnel, key) => (
                  <LinkShareModal
                    opened={opened}
                    link={getShareUrl(ShareResourceEnum.funnel, funnel.hash)}
                    label={t('dashboard.funnel.share_link')}
                    title={t('dashboard.funnel.share_funnel')}
                    onClose={onClose}
                    key={key}
                  />
                ),
              },
            ],
            activate: {
              shouldRender: funnel => isFunnelCreated(funnel),
              onChange: funnel => onActivateFunnel(funnel),
            },
            settings: {
              shouldRender: funnel => isFunnelCreated(funnel),
              fullCaption: t('dashboard.funnel.edit_funnel'),
              onModalOpen: funnelInstance => {
                setFunnelId(funnelInstance.id)
                setCurrentCurrency(funnel?.currency)
              },
              isFetching: !funnel || !paymentMethodsData,
              onModalClose: onModalClose,
              additionalData: additionalData,
              editable: [
                {
                  field: 'name',
                  onRender: (value, onChange, _, __, isPreFetching) => (
                    <FormInput
                      isPreFetching={isPreFetching}
                      key="funnel-name-edit"
                      label={t('global.name')}
                      placeholder={t('global.name')}
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      maxLength={256}
                      error={errors.name}
                    />
                  ),
                },
                [
                  {
                    field: 'domain',
                    onRender: (value, onChange, _, __, isPreFetching) => (
                      <FormSelect
                        isPreFetching={isPreFetching}
                        key={'funnel-domain-edit'}
                        label={
                          <div className={'flex gap-1 w-fit items-center'}>
                            {t('dashboard.funnel.funnel_domain')}
                            <Popover
                              label={
                                <TransLoco
                                  t={t}
                                  i18nKey={'dashboard.funnel.domain_tooltip'}
                                  components={{
                                    a: (
                                      <a
                                        rel="noreferrer"
                                        target={'_blank'}
                                        className="primary-link w-full"
                                        href={getDomainInfoLink(user?.dashboardLocale)}
                                      />
                                    ),
                                  }}
                                />
                              }
                              popoverClassName={'w-[200px] sm:w-[350px]'}
                            >
                              {show => (
                                <QuestionMarkIcon
                                  className={`${
                                    show ? 'fill-blue' : 'fill-gray-100'
                                  } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                                />
                              )}
                            </Popover>
                          </div>
                        }
                        data={userDomainsSelect}
                        onChange={data => onChange(data)}
                        value={value}
                        error={errors.domain}
                        withoutCloseIcon
                      />
                    ),
                  },

                  {
                    field: 'currency',
                    onRender: (value, _, state, __, isPreFetching, onChangeState) => (
                      <FormSelect
                        isPreFetching={isPreFetching}
                        key={'funnel-currency-edit'}
                        label={
                          <Hint label={t('dashboard.funnel.currency_change_info')}>
                            {t('global.currency')}
                          </Hint>
                        }
                        data={currencyData}
                        onChange={data => {
                          onChangeState({
                            ...state,
                            paymentMethods: [],
                            currency: data,
                          })
                          setCurrentCurrency(data)
                          showToast()
                        }}
                        value={value}
                        error={errors.currency}
                        withoutCloseIcon
                      />
                    ),
                  },
                ],
                {
                  field: 'paymentMethods',
                  onRender: (value, onChange, _, __, isPreFetching) => (
                    <PaymentMethods
                      isPreFetching={isPreFetching}
                      key={'funnel-payment-methods-edit'}
                      value={value}
                      onChange={onChange}
                      data={paymentMethodsData?.paymentMethods}
                      error={errors.paymentMethods}
                    />
                  ),
                },
              ],
              onEdit: (funnel, data) => onUpdateFunnel(funnel, data),
            },
            remove: { shouldRender: funnel => isFunnelCreated(funnel), onChange: onRemoveFunnel },
          }}
        />
      </div>
    </ListingLayout>
  )
}

export const getServerSideProps = getBaseServerSideProps(FunnelsLoco, 'header.top_menu.funnels')

export default Funnels
