import { FormInput, Modal, PrimaryButton, copyTextToClipboard } from '@systemeio/ui-shared'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import FormTextarea from './form-textarea'

export interface LinkShareModalProps {
  opened: boolean
  link: string
  title: string
  label: string
  onClose: () => void
  onOpen?: () => void
  showInTextArea?: boolean
  isLoading?: boolean
}

const LinkShareModal = ({
  link,
  opened,
  onClose,
  title,
  label,
  onOpen,
  showInTextArea = false,
  isLoading,
}: LinkShareModalProps) => {
  const { t } = useLocoTranslation()

  return (
    <Modal afterEnter={onOpen} opened={opened} onClose={onClose} title={title}>
      <form className="flex flex-col gap-6 lg:gap-10">
        <div className="flex justify-center flex-col gap-3">
          {showInTextArea ? (
            <FormTextarea label={label} value={link} disabled isLoading={isLoading} />
          ) : (
            <FormInput label={label} value={link} disabled isPreFetching={isLoading} />
          )}
        </div>
        <div className="flex justify-center gap-4">
          <PrimaryButton
            disabled={isLoading}
            onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              await copyTextToClipboard(link, t('global.copy_clipboard'))
              onClose()
            }}
          >
            {t('global.copy_to_clipboard_caption')}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  )
}

export default LinkShareModal
